import React, { FC } from 'react';
import connect from './connect';
import { useLogic } from './logic';
import { Modal, Pagination, Tab, TabsContainer, UserList } from './styles';
import { Props } from './types';

const FollowModal: FC<Props> = ({
  open,
  data,
  followers,
  followings,
  getFollowers,
  getFollowings,
  closeModal,
}) => {
  const {
    followingsPage,
    followCount,
    selectedTab,
    setSelectedTab,
    setFollowersPage,
    setFollowingsPage,
  } = useLogic({ data, followers, followings, getFollowers, getFollowings });

  return (
    <Modal open={open} onClose={closeModal}>
      {selectedTab ? (
        <TabsContainer>
          <Tab
            $isActive={selectedTab === 'followers'}
            onClick={() => setSelectedTab('followers')}
          >
            Followers ({followers?.count ?? 0})
          </Tab>
          <Tab
            $isActive={selectedTab === 'followings'}
            onClick={() => setSelectedTab('followings')}
          >
            Following ({followings?.count ?? 0})
          </Tab>
        </TabsContainer>
      ) : null}

      <UserList
        parentUser={{ id: data?.id, slug: data?.slug }}
        users={selectedTab === 'followers' ? followers : followings}
      />

      <Pagination
        current={Math.min(followingsPage, followCount ?? 0)}
        setPage={
          selectedTab === 'followers' ? setFollowersPage : setFollowingsPage
        }
        total={followCount ?? 0}
      />
    </Modal>
  );
};

export default connect(FollowModal);
