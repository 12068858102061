import { mapDispatchToProps } from './connect';

export const getFallbackUrl = ({
  path,
  about,
  gallery,
  contributions,
  activity,
  goPremium,
}: {
  path: string;
  about: boolean;
  gallery: boolean;
  contributions: boolean;
  activity: boolean;
  goPremium: boolean;
}) => {
  if (about) {
    return `${path}/about`;
  }
  if (gallery) {
    return `${path}/gallery`;
  }
  if (contributions) {
    return `${path}/contributions`;
  }
  if (activity) {
    return `${path}/activity`;
  }
  if (goPremium) {
    return `${path}/go-premium`;
  }
  return null;
};

export const handleFollowUser = ({
  follow,
  userSlug,
  followUser,
  unfollowUser,
}: {
  follow: boolean;
  userSlug: string;
  followUser: typeof mapDispatchToProps['followUser'];
  unfollowUser: typeof mapDispatchToProps['unfollowUser'];
}) => {
  if (follow) {
    followUser({ slug: userSlug });
  } else {
    unfollowUser({ slug: userSlug });
  }
};
