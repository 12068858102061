import Button from 'components/Button';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { getUserTypeLabel } from 'utils/getUserTypeLabel';
import {
  Avatar,
  ButtonsContainer,
  Container,
  Details,
  Info,
  Name,
  UserProfileFollowButton,
} from './styles';
import { Props } from './types';

const Member: FC<Props> = ({
  id,
  slug,
  followed,
  userType,
  name,
  location,
  avatar,
  isLoggedIn,
  onToggleFollowUser,
  ...rest
}) => {
  const getUserLabel = useMemo(() => {
    return getUserTypeLabel(userType);
  }, [userType]);

  const onClickToggleFollowUser = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onToggleFollowUser?.({ id, slug, followed: !!followed });
    },
    [followed, id, slug, onToggleFollowUser],
  );

  return (
    <Container {...rest}>
      <Avatar src={avatar} variant={userType} aria-hidden="true" />

      <Info>
        <Name>{name}</Name>

        <Details>
          {`${getUserLabel}${location ? ` · ${location}` : ''}`}
        </Details>
        <ButtonsContainer>
          {isLoggedIn ? (
            <UserProfileFollowButton
              followed={!!followed}
              onClick={onClickToggleFollowUser}
            />
          ) : null}
          <Button variant="secondary" mode="small" to={`/members/${slug}`}>
            View profile
          </Button>
        </ButtonsContainer>
      </Info>
    </Container>
  );
};

export default memo(Member);
