import { SelectedTab } from 'containers/Common/Modals/FollowModal/types';
import { AppContentType } from 'model';
import { ActionType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';
import * as actions from './actions';

export enum ModalType {
  CONFIRMATION = 'CONFIRMATION',
  PREMIUM_INTERRUPTION = 'PREMIUM_INTERRUPTION',
  REPORT = 'REPORT',
  SIGN_UP = 'SIGN_UP',
  FOLLOW = 'FOLLOW',
}

interface NoModal {
  current: null;
}

export interface ConfirmationModal {
  current: ModalType.CONFIRMATION;
  data: {
    acceptButtonLabel: string;
    acceptButtonVariant: 'primary' | 'secondary' | 'danger';
    cancelButtonLabel: string;
    description: string;
    onAcceptIntent: string;
    title: string;
  };
}

export interface PremiumInterruptionModal {
  current: ModalType.PREMIUM_INTERRUPTION;
}

export interface ReportModal {
  current: ModalType.REPORT;
  data: {
    contentType: AppContentType;
    objectId: string;
  };
}

export interface SignUpModal {
  current: ModalType.SIGN_UP;
}
export interface FollowModal {
  current: ModalType.FOLLOW;
  data: {
    id: string;
    slug: string;
    selectedTab: SelectedTab;
  };
}

export type ModalAction = ActionType<typeof actions>;

export type ModalState = DeepReadonly<
  | NoModal
  | ConfirmationModal
  | PremiumInterruptionModal
  | ReportModal
  | SignUpModal
  | FollowModal
>;
