import { connect } from 'react-redux';
import { selectUserId } from 'redux/modules/auth';
import { RootState } from 'redux/modules/types';
import { followUser, unfollowUser } from 'redux/modules/users';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { match: { params } }: OwnProps) => {
  const {
    auth,
    users: { bySlug, loading },
  } = state;
  const { userSlug } = params;
  const user = userSlug
    ? bySlug[userSlug]
    : auth.user && bySlug[auth.user.slug];
  const meUserId = selectUserId(state);

  return {
    user,
    meUserId,
    loading,
    isLoggedIn: !!auth.user,
  };
};

export const mapDispatchToProps = {
  followUser: followUser.request,
  unfollowUser: unfollowUser.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
