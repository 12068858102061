import {
  Modal as DefaultModal,
  Pagination as DefaultPagination,
} from 'components';
import { Content } from 'components/Modal/styles';
import styled, { css } from 'styled-components';
import DefaultUserList from './UsersList';

export const Modal = styled(DefaultModal)`
  margin: 0 auto;
  width: 100%;
  max-width: 35rem;

  ${Content} {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Tab = styled.div<{ $isActive: boolean }>`
  flex: 1;
  padding-block-start: 3rem;
  padding-block-end: 1.25rem;
  background-color: ${({ theme }) => theme.colors.grey200};
  font-size: 1.25rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.bold};
  border-bottom: 4px solid ${({ theme }) => theme.colors.grey300};
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.black};
      border-bottom: 4px solid ${({ theme }) => theme.colors.accent};
    `};
`;

export const UserList = styled(DefaultUserList)`
  overflow: auto;
`;

export const Pagination = styled(DefaultPagination)`
  padding-block-end: 1rem;
`;
