import styled from 'styled-components';

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.bold};
`;
