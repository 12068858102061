import { connect } from 'react-redux';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { RootState } from 'redux/modules/types';
import {
  followUser,
  getUsers,
  selectUsers,
  unfollowUser,
} from 'redux/modules/users';

const mapStateToProps = (state: RootState) => ({
  members: selectUsers(state),
  loading: state.users.loading,
  count: state.users.count,
  pageCount: state.users.pageCount,
  wentBack: selectUserWentBack(state),
  isLoggedIn: !!state.auth.user,
});

const mapDispatchToProps = {
  getMembers: getUsers.request,
  followUser: followUser.request,
  unfollowUser: unfollowUser.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
