import DefaultAvatar from 'components/Avatar';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import DefaultUserProfileFollowButton from 'components/UserProfileFollowButton';
import styled from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 8px;
  box-shadow: -0.8125rem 0.8125rem 1.875rem -1.4375rem ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};

  :hover {
    box-shadow: 0 0.125rem 1.25rem -0.4375rem ${({ theme }) => addAlpha(theme.colors.grey400, 0.7)};
  }
`;

export const Avatar = styled(DefaultAvatar)`
  border: 0px;
  height: 4.5rem;
  width: 4.5rem;
  margin-bottom: 0;
  margin-right: 1.125rem;

  ${BadgeContainer} {
    left: 80%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.125rem;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.125rem;
`;

export const Details = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-block-start: 0.5rem;

  > * {
    flex: 1;
  }
`;

export const UserProfileFollowButton = styled(DefaultUserProfileFollowButton)`
  width: fit-content;
  padding-inline: 1rem;
`;
