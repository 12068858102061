import UserProfileFollowButton from 'components/UserProfileFollowButton';
import React, { memo } from 'react';
import { toOrdinal } from './logic';
import {
  Actions,
  Avatar,
  Button,
  Container,
  Count,
  Counter,
  Counters,
  Details,
  Divider,
  FireButton,
  FireIcon,
  Followers,
  Header,
  Member,
  Name,
  Ordinal,
  Position,
  Social,
  Summary,
  Type,
} from './styles';
import { Props } from './types';

const RankingMember: React.FC<Props> = ({
  position,
  name,
  streaks = 0,
  followers = 0,
  datapoints = 0,
  comments = 0,
  uploads = 0,
  slug,
  avatar,
  followed,
  isLoggedIn,
  onToggleFollowUser,
  ...props
}) => {
  const { suffix } = toOrdinal(position);
  return (
    <Container {...props}>
      <Header $position={position}>
        <Position>
          {position}
          {suffix ? <Ordinal>{suffix}</Ordinal> : null}
        </Position>
      </Header>

      <Member>
        <Summary>
          <Avatar src={avatar} variant="default" aria-hidden="true" />

          <Details>
            <Name>{name}</Name>

            <Social>
              <FireButton>
                {streaks} <FireIcon />
              </FireButton>

              <Followers>{`${followers} ${
                followers === 1 ? 'Follower' : `Followers`
              }`}</Followers>
            </Social>
          </Details>
        </Summary>

        <Counters>
          <Counter>
            <Count>{datapoints}</Count>

            <Type>Datapoints</Type>
          </Counter>

          <Divider />

          <Counter>
            <Count>{comments}</Count>

            <Type>Comments</Type>
          </Counter>

          <Divider />

          <Counter>
            <Count>{uploads}</Count>

            <Type>Uploads</Type>
          </Counter>
        </Counters>

        <Actions>
          {isLoggedIn ? (
            <UserProfileFollowButton
              followed={!!followed}
              onClick={onToggleFollowUser}
            />
          ) : null}
          <Button variant="secondary" to={`/members/${slug}`}>
            View profile
          </Button>
        </Actions>
      </Member>
    </Container>
  );
};

export default memo(RankingMember);
