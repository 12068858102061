import { connect } from 'react-redux';
import { selectUserId } from 'redux/modules/auth';
import { closeModal } from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';
import { followUserList, unfollowUserList } from 'redux/modules/users';

const mapStateToProps = (state: RootState) => {
  const meUserId = selectUserId(state);
  return { meUserId, isLoggedIn: !!state.auth.user };
};

export const mapDispatchToProps = {
  followUser: followUserList.request,
  unfollowUser: unfollowUserList.request,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
