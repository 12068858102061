import {
  BannerWhite,
  Card,
  CardGrid,
  FormContainer,
  Loader,
  ModalRoute,
  PageTitle,
  Pagination,
  RichText,
  SectionTitle,
  UserProfileFollowButton,
} from 'components';
import FeaturedVideo from 'components/FeaturedVideo';
import VideoModal from 'containers/Common/Modals/VideoModal';
import SearchOptions from 'containers/Common/SearchOptions';
import { getUserName, Statuses } from 'model';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import excludeOtherTag from 'utils/excludeOtherTag';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import connect from './connect';
import NewExerciseResponseForm from './ExerciseResponseForm';
import { getVideoSolutionUrl, handleFormSubmitted } from './logic';
import NoResults from './NoResults';
import {
  Author,
  AuthorContainer,
  AuthorName,
  AuthorRow,
  Avatar,
  AvatarContainer,
  BannerButton,
  Challenge,
  ChallengeSection,
  Container,
  Content,
  FeaturedVideoTitle,
  Header,
  HeaderSection,
  Heading,
  HeadingRow,
  ImageContainer,
  ImageListViewer,
  InstructionSection,
  InstructionTitle,
  LastSection,
  Link,
  Navigation,
  PremiumTag,
  PremiumTagWrapper,
  Section,
  ShareButton,
  ShareStatusContainer,
  ShareTag,
  StatusBadge,
  SubmitButtonTop,
  SubmittedContent,
  Tags,
  TagsContainer,
  Title,
} from './styles';
import { Props } from './types';

const checkOutMoreButton = (
  <BannerButton to="/exercises">Check out more exercises!</BannerButton>
);

const ExerciseDetailRoot: FC<Props> = ({
  createExerciseResponse,
  exercise,
  getResponses,
  loadingExercise,
  loadingResponses,
  location: { hash, search, state },
  loggedIn,
  match: {
    params: { exerciseSlug },
    path,
    url,
  },
  pageCount,
  pathname,
  responses,
  wentBack,
  userIsPremium,
  followUser,
  unfollowUser,
}) => {
  const page = getPageFromQuery(search);
  const submissionsRef = useRef<HTMLDivElement>(null);

  const handleFollowUser = () => {
    if (exercise?.user.followed) {
      unfollowUser({ id: exercise.user.id, slug: exercise.user.slug });
    } else {
      followUser({ id: exercise?.user.id, slug: exercise?.user.slug });
    }
  };

  useEffect(() => {
    getResponses({ exerciseSlug, search, silent: wentBack });
  }, [exerciseSlug, getResponses, search, wentBack]);

  const solution = exercise?.videoSolution || exercise?.liveSolution;

  const videoSolutionUrl = getVideoSolutionUrl(exercise);

  const onFormSubmitted = useCallback(
    handleFormSubmitted({
      createExerciseResponse,
      exerciseId: exercise?.id,
    }),
    [createExerciseResponse, exercise?.id],
  );

  if (loadingExercise && !exercise) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!exercise) {
    return null;
  }

  const currentUrl = url
    ? window.location.origin + url
    : window.location.origin + pathname;
  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${exercise.name}: `;

  let lastSection;
  if (loggedIn && !exercise.submittedResponse) {
    lastSection = (
      <FormContainer>
        <SectionTitle>Hone your skills through practice!</SectionTitle>
        <NewExerciseResponseForm
          hidden={exercise.hidden}
          onSubmit={onFormSubmitted}
        />
      </FormContainer>
    );
  } else if (loggedIn && exercise.submittedResponse) {
    lastSection = (
      <BannerWhite
        title="You’ve already completed this exercise (nice work!)."
        subtitle="Peruse solutions to learn from others or browse the exercise bank to select your next exercise. Happy practicing!"
      >
        {checkOutMoreButton}
      </BannerWhite>
    );
  } else if (loggedIn && exercise.ended) {
    lastSection = (
      <BannerWhite title="We are sorry but this exercise is closed.">
        {checkOutMoreButton}
      </BannerWhite>
    );
  }

  let HeaderSubmitButton;
  if (exercise.submittedResponse && loggedIn) {
    HeaderSubmitButton = <StatusBadge>Exercise solved!</StatusBadge>;
  } else {
    HeaderSubmitButton = <SubmitButtonTop loggedIn={loggedIn} url={url} />;
  }

  const exerciseFilteredTags = exercise.tags.filter(excludeOtherTag);

  return (
    <Container>
      <PageTitle title={exercise.name} />
      <Navigation />
      <HeaderSection>
        <Header>
          <Title>{exercise.name}</Title>
          <AuthorRow>
            <AvatarContainer>
              <Avatar
                to={`/members/${exercise.user.id}`}
                variant={getUserType(exercise.user)}
                src={exercise.user.avatar}
              />
              <AuthorContainer>
                <Author>
                  Posted by{' '}
                  <AuthorName to={`/members/${exercise.user.id}`}>
                    {getUserName(exercise.user)}
                  </AuthorName>
                </Author>
                <TagsContainer>
                  <UserProfileFollowButton
                    followed={!!exercise.user.followed}
                    mode="tiny"
                    onClick={() => handleFollowUser()}
                  />
                  <Tags items={exerciseFilteredTags} oneLine={false} />
                </TagsContainer>
              </AuthorContainer>
            </AvatarContainer>
            <ShareStatusContainer>
              <ShareTag>
                <ShareButton content={shareButtonMessage} url={currentUrl} />
              </ShareTag>
              {HeaderSubmitButton}
            </ShareStatusContainer>
          </AuthorRow>
        </Header>
      </HeaderSection>
      <ChallengeSection>
        <Challenge>
          <ImageContainer>
            <ImageListViewer
              images={[exercise.image]}
              zoomUrl={{
                search,
                pathname: `${url}/images`,
              }}
            />
          </ImageContainer>
          <InstructionSection>
            <InstructionTitle>Instructions</InstructionTitle>
            <RichText content={exercise.context} />
          </InstructionSection>
        </Challenge>
      </ChallengeSection>
      {userIsPremium && solution && (
        <Section>
          <Content>
            <HeadingRow>
              <Heading>
                <SectionTitle>SWD team solution</SectionTitle>
              </Heading>
            </HeadingRow>
            <FeaturedVideo to={videoSolutionUrl} image={solution.thumbnail}>
              <FeaturedVideoTitle>
                SWD team solution to {exercise.name}
              </FeaturedVideoTitle>
              <PremiumTagWrapper>
                <PremiumTag>PREMIUM</PremiumTag>
              </PremiumTagWrapper>
            </FeaturedVideo>
          </Content>
        </Section>
      )}
      <Section id="submissions" ref={submissionsRef}>
        <Content>
          <HeadingRow>
            <Heading>
              <SectionTitle>
                Browse others’ solutions to this exercise
              </SectionTitle>
            </Heading>
          </HeadingRow>
          <SubmittedContent>
            {(responses.length > 0 || search) && (
              <SearchOptions
                contentType="exerciseresponse"
                searchPlaceholder="Search submissions to this exercise..."
              />
            )}
            {loadingResponses ? (
              <Loader />
            ) : (
              <>
                {responses.length > 0 ? (
                  <CardGrid>
                    {responses.map(
                      ({
                        commentsCount,
                        hits,
                        id,
                        mediaList,
                        lastComment,
                        slug,
                        swdTeam,
                        swdSolution,
                        tags,
                        title,
                        user,
                        votesCount,
                      }) => (
                        <Link key={id} to={`${url}/${slug}`}>
                          <Card
                            authorAvatar={user.avatar}
                            authorName={getUserName(user)}
                            authorType={getUserType(user)}
                            comments={commentsCount}
                            datapoints={votesCount}
                            lastCommentDate={
                              lastComment ? lastComment : undefined
                            }
                            statuses={[
                              ...(swdSolution
                                ? [Statuses.SOLVED_BY_TEAM]
                                : swdTeam
                                ? [Statuses.SWD_TEAM]
                                : []),
                            ]}
                            tags={tags}
                            thumbnail={mediaList?.mainMedia}
                            title={title}
                            views={hits}
                          />
                        </Link>
                      ),
                    )}
                  </CardGrid>
                ) : (
                  <NoResults
                    userIsPremium={userIsPremium}
                    exerciseEnded={!!exercise.ended}
                    submittedResponse={exercise.submittedResponse}
                    search={search}
                    url={url}
                  />
                )}
              </>
            )}
            <Pagination
              contentRef={submissionsRef}
              current={Math.min(page, pageCount)}
              hash={hash}
              search={search}
              state={state}
              total={pageCount}
              url={url}
            />
          </SubmittedContent>
        </Content>
      </Section>
      {(!exercise.submittedResponse || !exercise.ended) && (
        <LastSection id="submit-exercise">
          <Content>{lastSection}</Content>
        </LastSection>
      )}
      <ModalRoute component={VideoModal} path={`${path}/video-solution`} />
    </Container>
  );
};

export default connect(ExerciseDetailRoot);
