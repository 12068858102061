import { MembersSection } from 'containers/Main/Members/types';
import { SearchMembersForm } from 'model';
import { parse, stringify } from 'query-string';
import { USERS_ACTIVITY_PER_PAGE } from 'utils/config';
import { keysOf } from 'utils/keysOf';
import { mapDispatchToProps } from '../Profile/UserDetail/UserDetailRoot/connect';
import { ActivityOrderEnum } from './MembersTable/MembersOrders/Form/types';
import { Props } from './types';

export const handleFormSubmitted = ({
  hash,
  pathname,
  replace,
  search,
}: {
  hash: Props['location']['hash'];
  pathname: Props['location']['pathname'];
  replace: Props['history']['replace'];
  search: Props['location']['search'];
}) => ({ directionIsAsc, ...values }: SearchMembersForm) => {
  const params = {
    ...parse(search),
    ...values,
  };

  if (params.order) {
    params.order = `${directionIsAsc ? '' : '-'}${params.order}`;
  }

  const newSearch = stringify(
    keysOf(params).reduce(
      (acc, key) => ({
        ...acc,
        [key]: (() => {
          // If the field is falsy, remove it
          return params[key] || undefined;
        })(),
      }),
      {
        // If the search filters changed, go back to the first page
        page: undefined,
      },
    ),
  );

  replace({
    hash,
    pathname,
    search: newSearch,
  });
};

const MAP_ACTIVITY_ORDER: Record<ActivityOrderEnum, string> = {
  [ActivityOrderEnum.OVERALL_ACTIVITY]: 'outstanding-members',
  [ActivityOrderEnum.CHALLENGE_STREAK]: 'highest-streaks',
  [ActivityOrderEnum.COMMENTS]: 'more-comments',
  [ActivityOrderEnum.UPLOADS]: 'more-uploads',
  [ActivityOrderEnum.DATAPOINTS]: 'most-popular',
};

export const handleFiltersChanged = ({
  getMembers,
  period,
  search,
  section,
  wentBack,
}: {
  getMembers: Props['getMembers'];
  period: Props['match']['params']['period'];
  search: Props['location']['search'];
  section: MembersSection;
  wentBack: Props['wentBack'];
}) => () => {
  const { order, activityOrder, course, ...restSearch } = parse(search);

  const withRequiredPeriod = section === 'activity' || section === 'all';

  if (!period && withRequiredPeriod) {
    return;
  }

  getMembers({
    search: stringify({
      ...restSearch,
      ...(withRequiredPeriod
        ? {
            period,
          }
        : {}),
      ...(section === 'activity' &&
      typeof activityOrder === 'string' &&
      activityOrder
        ? {
            activityOrder:
              MAP_ACTIVITY_ORDER[activityOrder as ActivityOrderEnum],
          }
        : {}),
      ...(section === 'scholars'
        ? {
            graduate: course,
          }
        : {}),
      [(() => {
        switch (section) {
          case 'team':
            return 'swdTeam';
          case 'scholars':
            return 'graduates';
          case 'all':
            return 'newMembers';
          default:
            return section;
        }
      })()]: true,
      ...(section === 'all' && typeof order === 'string'
        ? {
            order_by: order.replace('-', ''),
            order_asc: !order.includes('-'),
          }
        : {}),
      pageSize: section === 'activity' ? USERS_ACTIVITY_PER_PAGE : undefined,
    }),
    silent: wentBack,
  });
};

export const handleFollowUser = ({
  followed,
  userId,
  userSlug,
  followUser,
  unfollowUser,
}: {
  followed: boolean;
  userId: string;
  userSlug: string;
  followUser: typeof mapDispatchToProps['followUser'];
  unfollowUser: typeof mapDispatchToProps['unfollowUser'];
}) => {
  if (followed) {
    unfollowUser({ id: userId, slug: userSlug });
  } else {
    followUser({ id: userId, slug: userSlug });
  }
};
