import produce from 'immer';
import {
  ChallengeResponse,
  Conversation,
  Discover,
  ExerciseResponse,
  FeedbackRequest,
  User,
} from 'model';
import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import {
  CHALLENGE_RESPONSES_PER_PAGE,
  CONVERSATIONS_PER_PAGE,
  DISCOVER_PER_PAGE,
  EXERCISE_RESPONSES_PER_PAGE,
  FEEDBACK_PER_PAGE,
  USERS_PER_PAGE,
} from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { getPageCount } from 'utils/getPageCount';
import {
  followUser,
  followUserList,
  getFollowers,
  getFollowings,
  getHeatMap,
  getOutstandingUsers,
  getUser,
  getUserChallengeResponses,
  getUserConversations,
  getUserExerciseResponses,
  getUserFeedbackResponses,
  getUserGallery,
  getUserLiked,
  getUsers,
  notifyLaunch,
  unfollowUser,
  unfollowUserList,
  _upsertUsersById,
} from './actions';
import { UsersAction, UsersState } from './types';

export const INITIAL_STATE: UsersState = {
  ...defaultStandardState,
  heatMapsBySlug: {},
  loadingOutstandingUsers: false,
  outstandingUsers: [],
};

const handleNestedSlugSuccess = ({
  slug,
  results,
  count,
  property,
  state,
  perPage,
}: {
  slug?: string;
  results: (
    | FeedbackRequest
    | Conversation
    | ExerciseResponse
    | ChallengeResponse
    | Discover
  )[];
  count: number;
  perPage: number;
  property: keyof User;
  state: UsersState;
}) => {
  if (!slug) {
    return state;
  }

  const selectedItem = state.bySlug[slug];

  if (!selectedItem) {
    return state;
  }

  return {
    ...state,
    bySlug: {
      ...state.bySlug,
      [slug]: {
        ...selectedItem,
        firstName: selectedItem.firstName,
        [property]: {
          slugs: results.map((x) => x.slug),
          pageCount: getPageCount(count, perPage),
          loading: false,
        },
      },
    },
  };
};

const handleNestedSlugRequestAndFailure = ({
  slug,
  property,
  state,
  loading,
}: {
  slug?: string;
  property: keyof User;
  state: UsersState;
  loading: boolean;
}) => {
  if (!slug) {
    return state;
  }

  const selectedItem = state.bySlug[slug];

  if (!selectedItem) {
    return state;
  }

  const propertyValue = selectedItem[property] || {};

  return {
    ...state,
    bySlug: {
      ...state.bySlug,
      [slug]: {
        ...selectedItem,
        [property]: {
          ...propertyValue,
          loading,
        },
      },
    },
  };
};

const handleUpdateUserCount = (
  payload: { id?: string; slug?: string },
  state: UsersState,
  increment: number,
) => {
  const { slug, id } = payload;
  const selectedItemBySlug = slug ? state.bySlug[slug] : undefined;
  const selectedItemById = id ? state.byId[id] : undefined;
  const selectedItem = selectedItemBySlug || selectedItemById;

  if (!selectedItem) {
    return state;
  }

  const selectedOutstandingUserIndex = state.outstandingUsers.findIndex(
    (user) => user.id === id,
  );
  const outstandingUsersArr = [...(state?.outstandingUsers ?? [])];
  outstandingUsersArr[selectedOutstandingUserIndex] = {
    ...state.outstandingUsers[selectedOutstandingUserIndex],
    followed: increment > 0,
  };

  return {
    ...state,
    bySlug: {
      ...state.bySlug,
      ...(selectedItem && slug
        ? {
            [slug]: {
              ...selectedItem,
              followersCount: (selectedItem.followersCount || 0) + increment,
              loading: false,
              followed: increment > 0,
            },
          }
        : {}),
    },
    byId: {
      ...state.byId,
      ...(selectedItem && id
        ? {
            [id]: {
              ...selectedItem,
              followersCount: (selectedItem.followers || 0) + increment,
              loading: false,
              followed: increment > 0,
            },
          }
        : {}),
    },
    outstandingUsers: outstandingUsersArr,
  };
};

const handleUpdateUserListCount = (
  payload: { id?: string; slug?: string; childId?: string },
  state: UsersState,
  increment: number,
) => {
  const { slug, id, childId } = payload;
  const selectedItemBySlug = slug ? state.bySlug[slug] : undefined;
  const selectedItemById = id ? state.byId[id] : undefined;
  const selectedItem = selectedItemBySlug || selectedItemById;

  if (!selectedItem?.followersList || !selectedItem.followingsList) {
    return state;
  }

  const selectedFollower = selectedItem.followersList.results.find(
    (u) => u.id === childId,
  );

  const selectedFollowing = selectedItem.followingsList.results.find(
    (u) => u.id === childId,
  );

  return {
    ...state,
    bySlug: {
      ...state.bySlug,
      ...(selectedItem && slug
        ? {
            [slug]: {
              ...selectedItem,
              loading: false,
              followersList: {
                ...selectedItem.followersList,
                slug: selectedItem.followersList.slug,
                ...(selectedFollower && {
                  results: [
                    ...selectedItem.followersList.results.filter(
                      (u) => u.id !== childId,
                    ),
                    { ...selectedFollower, followed: increment > 0 },
                  ],
                }),
              },
              followingsList: {
                ...selectedItem.followingsList,
                slug: selectedItem.followingsList.slug,
                ...(selectedFollowing && {
                  results: [
                    ...selectedItem.followingsList.results.filter(
                      (u) => u.id !== childId,
                    ),
                    { ...selectedFollowing, followed: increment > 0 },
                  ],
                }),
              },
            },
          }
        : {}),
    },
    byId: {
      ...state.byId,
      ...(selectedItem && id
        ? {
            [id]: {
              ...selectedItem,
              followersCount: (selectedItem.followers || 0) + increment,
              loading: false,
              followed: increment > 0,
            },
          }
        : {}),
    },
  };
};

const reducer: Reducer<UsersState, UsersAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    // Nested Feedback
    case getType(getUserFeedbackResponses.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'feedbackResponseSlugs',
        loading: false,
      });
    }
    case getType(getUserFeedbackResponses.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'feedbackResponseSlugs',
        loading: true,
      });
    }
    case getType(getUserFeedbackResponses.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: FEEDBACK_PER_PAGE,
        property: 'feedbackResponseSlugs',
      });
    }

    // Nested Conversations
    case getType(getUserConversations.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'conversationSlugs',
        loading: false,
      });
    }
    case getType(getUserConversations.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'conversationSlugs',
        loading: true,
      });
    }
    case getType(getUserConversations.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: CONVERSATIONS_PER_PAGE,
        property: 'conversationSlugs',
      });
    }

    // Nested ExerciseResponse
    case getType(getUserExerciseResponses.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'exerciseResponseSlugs',
        loading: true,
      });
    }
    case getType(getUserExerciseResponses.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'exerciseResponseSlugs',
        loading: false,
      });
    }
    case getType(getUserExerciseResponses.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: EXERCISE_RESPONSES_PER_PAGE,
        property: 'exerciseResponseSlugs',
      });
    }

    // Nested ChallengeResponse
    case getType(getUserChallengeResponses.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'challengeResponseSlugs',
        loading: true,
      });
    }
    case getType(getUserChallengeResponses.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'challengeResponseSlugs',
        loading: false,
      });
    }
    case getType(getUserChallengeResponses.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: CHALLENGE_RESPONSES_PER_PAGE,
        property: 'challengeResponseSlugs',
      });
    }

    // Nested Gallery
    case getType(getUserGallery.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'gallerySlugs',
        loading: true,
      });
    }
    case getType(getUserGallery.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'gallerySlugs',
        loading: false,
      });
    }
    case getType(getUserGallery.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: DISCOVER_PER_PAGE,
        property: 'gallerySlugs',
      });
    }

    // Nested Liked
    case getType(getUserLiked.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'likedSlugs',
        loading: true,
      });
    }
    case getType(getUserLiked.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'likedSlugs',
        loading: false,
      });
    }
    case getType(getUserLiked.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: DISCOVER_PER_PAGE,
        property: 'likedSlugs',
      });
    }

    // Notify launch
    case getType(notifyLaunch.request): {
      return {
        ...state,
        loading: true,
      };
    }
    case getType(notifyLaunch.failure):
    case getType(notifyLaunch.success): {
      return {
        ...state,
        loading: false,
      };
    }

    // Heatmap
    case getType(getHeatMap.request):
    case getType(getHeatMap.failure):
      return {
        ...state,
        loading: true,
      };
    case getType(getHeatMap.success): {
      const { data, slug } = action.payload;

      return produce(state, (next) => {
        next.heatMapsBySlug[slug] = data;
        next.loading = false;
      });
    }

    // Outstanding users
    case getType(getOutstandingUsers.failure):
      return {
        ...state,
        loadingOutstandingUsers: false,
        outstandingUsers: [],
      };
    case getType(getOutstandingUsers.request):
      return {
        ...state,
        loadingOutstandingUsers: true,
      };
    case getType(getOutstandingUsers.success):
      return {
        ...state,
        loadingOutstandingUsers: false,
        outstandingUsers: action.payload.results,
      };

    // Follow
    case getType(followUser.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: false,
      });
    }
    case getType(followUser.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: true,
      });
    }
    case getType(followUser.success): {
      return handleUpdateUserCount(action.payload, state, 1);
    }

    // Unfollow
    case getType(unfollowUser.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: false,
      });
    }
    case getType(unfollowUser.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: true,
      });
    }
    case getType(unfollowUser.success): {
      return handleUpdateUserCount(action.payload, state, -1);
    }

    // Follow from list
    case getType(followUserList.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: false,
      });
    }
    case getType(followUserList.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: true,
      });
    }
    case getType(followUserList.success): {
      return handleUpdateUserListCount(action.payload, state, 1);
    }

    // Unfollow from list
    case getType(unfollowUserList.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: false,
      });
    }
    case getType(unfollowUserList.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followed',
        loading: true,
      });
    }
    case getType(unfollowUserList.success): {
      return handleUpdateUserListCount(action.payload, state, -1);
    }

    // Followers
    case getType(getFollowers.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followersList',
        loading: false,
      });
    }
    case getType(getFollowers.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followersList',
        loading: true,
      });
    }
    case getType(getFollowers.success): {
      const selectedItem = state.bySlug[action.payload.slug];

      if (!selectedItem) {
        return state;
      }

      return {
        ...state,
        bySlug: {
          ...state.bySlug,
          [action.payload.slug]: {
            ...selectedItem,
            followersList: action.payload,
          },
        },
      };
    }

    // Followings
    case getType(getFollowings.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followingsList',
        loading: false,
      });
    }
    case getType(getFollowings.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'followingsList',
        loading: true,
      });
    }
    case getType(getFollowings.success): {
      const selectedItem = state.bySlug[action.payload.slug];

      if (!selectedItem) {
        return state;
      }

      return {
        ...state,
        bySlug: {
          ...state.bySlug,
          [action.payload.slug]: {
            ...selectedItem,
            followingsList: action.payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getUsers.request),
      success: isActionOf(getUsers.success),
      failure: isActionOf(getUsers.failure),
    },
    getOne: {
      request: isActionOf(getUser.request),
      success: isActionOf(getUser.success),
      failure: isActionOf(getUser.failure),
    },
    _upsertById: isActionOf(_upsertUsersById),
  },
  contractItem: (payload) => payload,
  initialState: INITIAL_STATE,
  perPage: USERS_PER_PAGE,
})(reducer);
