import { mapDispatchToProps } from './connect';

export const handleFollowUser = ({
  followed,
  userId,
  slug,
  followUser,
  unfollowUser,
}: {
  followed: boolean;
  userId: string;
  slug: string;
  followUser: typeof mapDispatchToProps['followUser'];
  unfollowUser: typeof mapDispatchToProps['unfollowUser'];
}) => {
  if (followed) {
    unfollowUser({ slug, id: userId });
  } else {
    followUser({ slug, id: userId });
  }
};
