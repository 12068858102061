import { Loader, ModalRoute, PageTitle } from 'components';
import FeedbackRequestView from 'containers/Common/FeedbackRequestView';
import {
  getFeedbackRequestStatusList,
  getUserName,
  isFeedbackRequestClosed,
} from 'model';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { getUserType } from 'utils/getUserType';
import { useConnect } from './connect';
import Edit from './Edit';
import { Comments, Container, Navigation, PostWrapper } from './styles';
import { Props } from './types';

const FeedbackDetail: FC<Props> = () => {
  const {
    feedback,
    getFeedbackRequest,
    id,
    loading,
    path,
    url,
    userIsAuthor,
  } = useConnect();

  useEffect(() => {
    getFeedbackRequest({ id });
  }, [getFeedbackRequest, id]);

  if (loading && !feedback) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!feedback) {
    return null;
  }

  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${feedback.title}: `;

  const feedbackClosed = isFeedbackRequestClosed(feedback);

  return (
    <Container>
      <PageTitle title={feedback.title} />
      <BreadcrumbsItem to={url}>{feedback?.title}</BreadcrumbsItem>
      <Navigation />
      <PostWrapper>
        <FeedbackRequestView
          authorAvatar={feedback.user.avatar}
          authorId={feedback.user.id}
          authorType={getUserType(feedback.user)}
          authorName={getUserName(feedback.user)}
          authorSlug={feedback.user.slug}
          closed={feedbackClosed}
          context={feedback.context}
          date={feedback.created}
          expiryDate={feedback.expiryDate}
          id={feedback.id}
          images={
            feedback.imageList
              ? [
                  feedback.imageList.mainImage,
                  ...feedback.imageList.extraImages,
                ]
              : undefined
          }
          isReported={feedback.isReported}
          isVoted={feedback.isVoted}
          open={!!feedback.id}
          scroll={false}
          shareButtonMessage={shareButtonMessage}
          statusList={getFeedbackRequestStatusList(feedback)}
          tags={feedback.tags}
          title={feedback.title}
          url={url}
          views={feedback.hits}
          votes={feedback.votesCount}
          followed={feedback.user.followed}
        />
      </PostWrapper>
      <Comments
        contentType="feedback"
        disabled={feedbackClosed}
        id={id}
        path={path}
        url={url}
      />
      {userIsAuthor && <ModalRoute component={Edit} path={`${path}/edit`} />}
    </Container>
  );
};

export default FeedbackDetail;
