import DefaultAvatar from 'components/Avatar';
import { StatusesAndTags as DefaultStatusesAndTags } from 'components/Conversation/styles';
import DefaultLink from 'components/Link';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3.6875rem auto;
  column-gap: ${({ theme }) => theme.spacing.md};
  row-gap: 0.25rem;
  margin: 1.1875rem 0 1.5625rem;
`;

export const Avatar = styled(DefaultAvatar)`
  box-sizing: border-box;
  display: block;
  grid-row: span 2 / auto;
  grid-column: span 10%;
  height: 3.6875rem;
  width: 3.6875rem;

  ${BadgeContainer} {
    height: 1.4375rem;
    width: 1.4375rem;
    bottom: -4px;
    left: 38px;
  }
`;

export const StatusesAndTags = styled(DefaultStatusesAndTags)`
  grid-column: 1/3;
  grid-row: 3/4;
  margin-top: 0;
  align-items: baseline;
  gap: 0.5rem;

  ${from.tablet`
    grid-column: 2;
    margin-top: 0;
    padding-left: 0;
    grid-row: 2/3;
  `};
`;

export const Name = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

export const DotSeparator = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0 ${({ theme }) => theme.spacing.sm};
  display: none;

  ${from.tablet`
    display: inline;
`}
`;

export const ViewsDotSeparator = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0 ${({ theme }) => theme.spacing.sm};
  display: none;

  ${from.mobile`
    display: inline;
  `}
`;

export const Info = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.5;
`;

export const Views = styled.div``;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${from.mobile`
    flex-direction: row;
  `}
`;

export const UserInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${from.tablet`
      flex-wrap:nowrap;
      flex-direction: row;
      align-items: center;
  `};
`;
