import {
  Avatar as DefaultAvatar,
  UserProfileFollowButton as DefaultUserProfileFollowButton,
} from 'components';
import { Picture } from 'components/Avatar/styles';
import styled from 'styled-components';

export const UserCardContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding-block: 0.75rem;
  padding-inline: 1.25rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
`;

export const UserCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Avatar = styled(DefaultAvatar)`
  width: fit-content;
  height: fit-content;
  margin: unset;

  ${Picture} {
    width: 3.25rem;
    height: auto;
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.bold};
  cursor: pointer;
`;

export const UserProfileFollowButton = styled(DefaultUserProfileFollowButton)`
  width: unset;
  margin-inline-start: auto;
`;
