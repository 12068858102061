import { Link, Loader, NoResults, Pagination } from 'components';
import { getUserName } from 'model';
import React from 'react';
import { USERS_ACTIVITY_PER_PAGE } from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { getUserTypeLabel } from 'utils/getUserTypeLabel';
import connect from './connect';
import { handleFollowUser } from './logic';
import {
  Avatar,
  Container,
  Details,
  Info,
  MembersOrders,
  Name,
  Position,
  Table,
  TableContainer,
  TBody,
  TD,
  TH,
  THead,
  TR,
  UserProfileFollowButton,
} from './styles';
import { Column, Props } from './types';

const COLUMNS: Column[] = [
  {
    label: 'Ranking',
  },
  {
    label: 'Avatar',
    hidden: true,
  },
  {
    label: 'Member',
    textAlign: 'left',
  },
  {
    label: 'Datapoints',
  },
  {
    label: 'Streak',
  },
  {
    label: 'Comments',
  },
  {
    label: 'Uploads',
  },
  {
    label: 'Followers',
  },
  {
    label: '',
  },
];

const MembersTable: React.FC<Props> = ({
  contentRef,
  search,
  state,
  url,
  loading,
  count,
  members,
  isLoggedIn,
  followUser,
  unfollowUser,
  handleFormSubmitted,
}) => {
  const page = getPageFromQuery(search);
  const pageCount = getPageCount(count, USERS_ACTIVITY_PER_PAGE);
  const hasMembers = members.length > 0;

  return (
    <>
      <Container>
        {loading && count === 0 ? (
          <Loader />
        ) : (
          <>
            {hasMembers ? (
              <>
                <MembersOrders handleFormSubmitted={handleFormSubmitted} />

                <TableContainer>
                  <Table>
                    <THead>
                      <TR>
                        {COLUMNS.map(({ label, textAlign, hidden }, index) => {
                          return (
                            <TH
                              key={index}
                              $textAlign={textAlign}
                              $hidden={hidden}
                            >
                              <span>{label}</span>
                            </TH>
                          );
                        })}
                      </TR>
                    </THead>

                    <TBody>
                      {loading ? (
                        <TR>
                          <TD colSpan={8}>
                            <Loader />
                          </TD>
                        </TR>
                      ) : (
                        members.map((member) => {
                          const {
                            id,
                            ranking,
                            avatar,
                            firstName,
                            lastName,
                            location,
                            dataPoints,
                            challengeStreak,
                            comments,
                            uploads,
                            followers,
                            slug,
                            followed,
                          } = member;

                          return (
                            <TR key={id}>
                              <TD $textAlign="center">
                                <Position>{ranking}</Position>
                              </TD>

                              <TD $textAlign="center">
                                <Avatar
                                  src={avatar}
                                  variant="default"
                                  aria-hidden="true"
                                />
                              </TD>

                              <TD>
                                <Link to={`/members/${slug}`}>
                                  <Info>
                                    <Name>
                                      {getUserName({ firstName, lastName })}
                                    </Name>

                                    <Details>
                                      {`${getUserTypeLabel(
                                        getUserType(member),
                                      )}${location ? ` · ${location}` : ''}`}
                                    </Details>
                                  </Info>
                                </Link>
                              </TD>

                              <TD $textAlign="center">{dataPoints}</TD>

                              <TD $textAlign="center">{challengeStreak}</TD>

                              <TD $textAlign="center">{comments}</TD>

                              <TD $textAlign="center">{uploads}</TD>

                              <TD $textAlign="center">{followers}</TD>

                              <TD $textAlign="center">
                                {isLoggedIn ? (
                                  <UserProfileFollowButton
                                    followed={!!followed}
                                    onClick={() =>
                                      handleFollowUser({
                                        slug,
                                        followUser,
                                        unfollowUser,
                                        followed: !!followed,
                                        userId: id,
                                      })
                                    }
                                  />
                                ) : null}
                              </TD>
                            </TR>
                          );
                        })
                      )}
                    </TBody>
                  </Table>
                </TableContainer>

                <Pagination
                  contentRef={contentRef}
                  current={Math.min(page, pageCount)}
                  state={state}
                  total={pageCount}
                  search={search}
                  url={url}
                />
              </>
            ) : (
              <NoResults />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default connect(MembersTable);
