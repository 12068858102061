import { SelectedTab } from 'containers/Common/Modals/FollowModal/types';
import { openFollow } from 'redux/modules/modal';
import { useActions } from 'utils/useActions';

export const useLogic = () => {
  const { openFollow: openFollowModal } = useActions({
    openFollow,
  });

  const handleOpenFollowModal = ({
    id,
    slug,
    selectedTab,
  }: {
    id: string;
    slug: string;
    selectedTab: SelectedTab;
  }) => {
    openFollowModal({ id, slug, selectedTab });
  };

  return { handleOpenFollowModal };
};
