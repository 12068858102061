import React, { FC, memo } from 'react';
import { getUserType } from 'utils/getUserType';
import { getUserTypeLabel } from 'utils/getUserTypeLabel';
import {
  Avatar,
  Name,
  UserCardContainer,
  UserCardInfo,
  UserProfileFollowButton,
} from './styles';
import { Props } from './types';

const UserCard: FC<Props> = ({
  user,
  meUserId,
  isLoggedIn,
  onClickFollowUser,
  onClickProfileUser,
}) => {
  return (
    <UserCardContainer>
      <Avatar variant={'default'} src={user.avatar} />
      <UserCardInfo>
        <Name onClick={() => onClickProfileUser(user.slug)}>
          {user.firstName} {user.lastName}
        </Name>
        {`${getUserTypeLabel(getUserType(user))}${
          user.location ? ` · ${user.location}` : ''
        }`}
      </UserCardInfo>
      {isLoggedIn && user.id !== meUserId ? (
        <UserProfileFollowButton
          followed={!!user.followed}
          onClick={() => onClickFollowUser(user)}
        />
      ) : null}
    </UserCardContainer>
  );
};

export default memo(UserCard);
