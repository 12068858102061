import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';
import {
  getOutstandingUsers,
  selectOutstandingUsers,
} from 'redux/modules/users';

const mapStateToProps = (state: RootState) => ({
  loading: state.users.loadingOutstandingUsers,
  members: selectOutstandingUsers(state),
  isLoggedIn: !!state.auth.user,
});

const mapDispatchToProps = {
  getMembers: getOutstandingUsers.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
