import { connect } from 'react-redux';
import {
  closeModal,
  selectFollowModalData,
  selectFollowModalOpen,
} from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';
import { getFollowers, getFollowings } from 'redux/modules/users';

const mapStateToProps = (state: RootState) => {
  const {
    users: { bySlug, loading },
  } = state;

  const open = selectFollowModalOpen(state);
  const data = selectFollowModalData(state);
  const user = data?.slug ? bySlug[data.slug] : undefined;
  const followers = user?.followersList;
  const followings = user?.followingsList;

  return {
    open,
    data,
    followers,
    followings,
    loading,
  };
};

const mapDispatchToProps = {
  closeModal,
  getFollowers: getFollowers.request,
  getFollowings: getFollowings.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
