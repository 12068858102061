import Statuses from 'components/Statuses';
import Tags from 'components/Tags';
import UserProfileFollowButton from 'components/UserProfileFollowButton';
import React, { FC, memo, useCallback } from 'react';
import { followUser, unfollowUser } from 'redux/modules/users';
import { formatDateAndTime } from 'utils/dates';
import excludeOtherTag from 'utils/excludeOtherTag';
import { pluralize } from 'utils/pluralize';
import { useActions } from 'utils/useActions';
import {
  Avatar,
  Container,
  DotSeparator,
  Info,
  InfoWrapper,
  Name,
  StatusesAndTags,
  UserInfo,
  Views,
  ViewsDotSeparator,
} from './styles';
import { Props } from './types';

const PostAuthor: FC<Props> = ({
  authorType,
  amaEndDate = null,
  avatar,
  closed = false,
  comments,
  date,
  isAma = false,
  isMonthly = false,
  isSpotlight,
  live = false,
  name,
  slug,
  spotlightDate,
  spotlightImage,
  spotlightUsers,
  statuses,
  tags,
  views,
  id,
  followed = false,
  ...rest
}) => {
  const actions = useActions({
    followUser: followUser.request,
    unfollowUser: unfollowUser.request,
  });

  const handleFollowUser = useCallback(() => {
    if (followed) {
      actions.unfollowUser({ id, slug });
    } else {
      actions.followUser({ id, slug });
    }
  }, [actions, followed, id, slug]);

  const filteredTags = tags
    ?.filter((tag) => !statuses?.includes(tag))
    .filter(excludeOtherTag);

  return (
    <Container id={id} {...rest}>
      <Avatar
        to={`/members/${slug}`}
        src={avatar}
        variant={authorType}
        name={name}
      />
      <UserInfo>
        <Name to={`/members/${slug}`}>{name}</Name>
        <InfoWrapper>
          <DotSeparator>·</DotSeparator>
          <Info>{formatDateAndTime(date)}</Info>
          {typeof views !== 'undefined' && (
            <Views>
              <ViewsDotSeparator>·</ViewsDotSeparator>
              <Info>
                {views} {pluralize(views, 'view', 'views')}
              </Info>
            </Views>
          )}
          {typeof comments !== 'undefined' && (
            <Views>
              <ViewsDotSeparator>·</ViewsDotSeparator>
              <Info>
                {comments} {pluralize(comments, 'comment', 'comments')}
              </Info>
            </Views>
          )}
        </InfoWrapper>
      </UserInfo>
      <StatusesAndTags>
        {statuses && statuses.length > 0 && (
          <Statuses
            isAma={isAma}
            live={live}
            closed={closed}
            amaEndDate={amaEndDate}
            statuses={statuses}
          />
        )}
        <UserProfileFollowButton
          followed={followed}
          mode="tiny"
          onClick={() => handleFollowUser()}
        />
        {filteredTags && <Tags items={filteredTags} />}
      </StatusesAndTags>
    </Container>
  );
};

export default memo(PostAuthor);
