import { useEffect, useMemo, useState } from 'react';
import { FOLLOW_USER_PER_PAGE } from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { Props, SelectedTab } from './types';

export const useLogic = ({
  data,
  followers,
  followings,
  getFollowers,
  getFollowings,
}: {
  data: Props['data'];
  followers: Props['followers'];
  followings: Props['followings'];
  getFollowers: Props['getFollowers'];
  getFollowings: Props['getFollowings'];
}) => {
  const [selectedTab, setSelectedTab] = useState<SelectedTab | null>(null);
  const [followersPage, setFollowersPage] = useState(1);
  const [followingsPage, setFollowingsPage] = useState(1);

  const followCount = useMemo(() => {
    const count =
      (selectedTab === 'followers' ? followers?.count : followings?.count) ?? 0;
    return getPageCount(count, FOLLOW_USER_PER_PAGE);
  }, [selectedTab, followers, followings]);

  useEffect(() => {
    if (data) {
      setSelectedTab(data?.selectedTab);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const { id, slug } = data;
      getFollowers({ id, slug, page: followersPage });
    }
  }, [data, followersPage, getFollowers]);

  useEffect(() => {
    if (data) {
      const { id, slug } = data;
      getFollowings({ id, slug, page: followingsPage });
    }
  }, [data, followingsPage, getFollowings]);

  return {
    followingsPage,
    followCount,
    selectedTab,
    setSelectedTab,
    setFollowersPage,
    setFollowingsPage,
  };
};
