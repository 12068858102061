import { mapDispatchToProps } from './connect';

export const handleFollowUser = ({
  followed,
  childId,
  childSlug,
  userId,
  slug,
  followUser,
  unfollowUser,
}: {
  followed: boolean;
  childId: string;
  childSlug: string;
  userId?: string;
  slug?: string;
  followUser: typeof mapDispatchToProps['followUser'];
  unfollowUser: typeof mapDispatchToProps['unfollowUser'];
}) => {
  if (followed) {
    unfollowUser({ slug, id: userId, childId, childSlug });
  } else {
    followUser({ slug, id: userId, childId, childSlug });
  }
};
