import {
  Expander,
  Link as DefaultLink,
  ShareButton as DefaultShareButton,
  StatusBadge as DefaultStatusBadge,
} from 'components';
import DefaultAvatar from 'components/Avatar';
import DefaultImageListViewer from 'components/ImageListViewer';
import DefaultNavigation from 'components/Navigation';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';
import SubmitButton from './SubmitButton';

const IMAGE_OVERLAP = '2rem';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const HeaderSection = styled.div`
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  ${container};
  ${from.tablet`
    display: block;
  `};
`;

export const Navigation = styled(DefaultNavigation)`
  padding-right: ${({ theme }) => theme.wrapperPaddingH.mobile};
  padding-left: ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding-right: ${({ theme }) => theme.wrapperPaddingH.desktop};
    padding-left: ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
  > :first-child {
    padding: 1rem 0;
  }
`;

export const TitleRow = styled.div`
  display: contents;
  flex-wrap: wrap;
  align-items: center;
  ${from.tablet`
    display: flex;
  `};
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: 1.313rem;
  margin-top: 0;
  width: 100%;
  ${from.tablet`
    font-size: 2.5rem;
    line-height: 2.5rem;
    width: auto;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    margin-right: auto;
  `};
`;

export const ShareTag = styled.div`
  display: contents;
  align-self: flex-end;
  margin-left: auto;
  ${from.tablet`
    display: flex;
    margin-left: 0;
  `};

  ${from.tabletH`
    margin-right: 0;
    margin-left: auto;
`}
`;

export const Avatar = styled(DefaultAvatar)`
  margin-right: 0.8rem;
  height: 2.75rem;
  width: 2.75rem;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 100%;
  ${from.tablet`
    width: auto;
  `};
`;

export const AuthorContainer = styled.div``;

export const AuthorName = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-block-end: 0.25rem;

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

export const AuthorRow = styled.div`
  display: contents;
  margin-top: 0.7rem;
  ${from.tablet`
  display: flex;
  `};
`;

export const Author = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.grey400};
  padding-top: 0.1rem;
`;

export const Deadline = styled.p`
  margin: 0 ${({ theme }) => theme.spacing.sm} 0 0;
`;

export const DeadlineDate = styled.p<{ closed: boolean }>`
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
  ${({ closed }) =>
    closed &&
    css`
      font-weight: ${({ theme }) => theme.weights.regular};
    `}
`;

export const DeadlineText = styled.span`
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
  > span {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
  ${from.tablet`
    margin: 0;
  `};
`;

export const DeadlineInfoContainer = styled.div`
  display: flex;
  order: 1;
  font-size: 1rem;
  text-transform: uppercase;
  margin: ${({ theme }) => theme.spacing.md} 0;
  align-items: center;
  letter-spacing: 1px;
  width: 100%;
  ${from.tablet`
    order: 0;
    width: auto;
    margin: 0 ${({ theme }) => theme.spacing.md} 0 0;
  `};

  ${from.tabletH`
    margin-left: auto;
`}
`;

export const ChallengeSection = styled.div`
  padding: 2.5rem ${({ theme }) => theme.wrapperPaddingH.mobile} 0;

  ${from.mobileXL`
    padding: 2.5rem ${({ theme }) => theme.wrapperPaddingH.desktop} 0;
  `};
`;

export const Challenge = styled.div`
  ${container};
  padding-bottom: ${({ theme }) => theme.spacing.xlg};

  ${from.tablet`
    padding-bottom: 3.75rem;
  `};
`;

export const ImageListViewer = styled(DefaultImageListViewer)`
  width: 100%;
  object-fit: cover;
  margin-top: -${IMAGE_OVERLAP};
  border-radius: 0.25rem;
  height: auto;

  img {
    height: fit-content;
    max-height: 24.63rem;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const DynamicSections = styled.div`
  margin: ${({ theme }) => theme.spacing.lg} auto 0 auto;
  max-width: 50rem;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }

  img {
    border-radius: 0.25rem;
    max-width: 100%;
  }
`;

export const DynamicSection = styled(Expander)`
  p {
    line-height: 1.75rem;
  }
`;

export const Section = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 1.875rem ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 3.75rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Content = styled.div`
  ${container};
`;

export const LastSection = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};

  ${from.mobile`
    padding: 2.5rem ${({ theme }) => theme.wrapperPaddingH.mobile} 5rem;
`};

  ${from.mobileXL`
    padding: 5rem ${({ theme }) => theme.wrapperPaddingH.desktop} 10rem;
  `};
`;

export const HeadingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Heading = styled.div``;

export const SectionDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 0;

  @media (min-width: 747px) {
    margin-right: 1rem;
  }

  ${from.tablet`
    font-size: 1.25rem;
`}
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const SubmitButtonTop = styled(SubmitButton)`
  order: 2;
  align-self: center;
  margin: 0 ${({ theme }) => theme.spacing.md} 0 0;
  ${from.tablet`
    margin: 0 0 0 auto;
  `};
`;

export const SubmitButtonBottom = styled(SubmitButton)`
  ${from.tablet`
      margin: 1rem 0 0 0;
    `};
`;

export const ShareButton = styled(DefaultShareButton)`
  order: 3;
  div ~ div {
    left: inherit;
    right: 0;
    ${from.tablet`
      left: inherit;
      right: 0;
      order: 0;
    `};
  }
`;

export const StatusBadge = styled(DefaultStatusBadge)`
  display: inline;
  padding: 0.8125rem 1rem;
  order: 2;
  position: relative;
  margin-left: 0;
  margin-right: ${({ theme }) => theme.spacing.md};
  align-self: center;
  ${from.tablet`
    margin-right: 0;
    order: 0;
    margin-left: auto;
  `};
`;

export const StatusBadgeSubmissions = styled(DefaultStatusBadge)`
  padding: ${({ theme }) => theme.spacing.sm} 0;
`;
