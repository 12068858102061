import {
  ChallengeResponse,
  Conversation,
  Discover,
  ExerciseResponse,
  FeedbackRequest,
  LaunchSoonForm,
  MonthData,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseWithSlug,
  UpsertByIdPayload,
  User,
} from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getUsers = createAsyncAction(
  'users/GET_USERS_REQUEST',
  'users/GET_USERS_SUCCESS',
  'users/GET_USERS_FAILURE',
)<{ search?: string; silent?: boolean }, PaginatedResponse<User>, undefined>();

export const getUser = createAsyncAction(
  'users/GET_USER_REQUEST',
  'users/GET_USER_SUCCESS',
  'users/GET_USER_FAILURE',
)<{ slug: string }, User, undefined>();

export const notifyLaunch = createAsyncAction(
  'users/NOTIFY_LAUNCH_REQUEST',
  'users/NOTIFY_LAUNCH_SUCCESS',
  'users/NOTIFY_LAUNCH_FAILURE',
)<LaunchSoonForm, undefined, undefined>();

export const getUserGallery = createAsyncAction(
  'users/GET_USER_GALLERY_REQUEST',
  'users/GET_USER_GALLERY_SUCCESS',
  'users/GET_USER_GALLERY_FAILURE',
)<
  PaginatedRequest & { slug: string; count?: number; search: string },
  PaginatedResponseWithSlug<Discover>,
  { slug: string }
>();

export const getUserLiked = createAsyncAction(
  'users/GET_USER_LIKED_REQUEST',
  'users/GET_USER_LIKED_SUCCESS',
  'users/GET_USER_LIKED_FAILURE',
)<
  PaginatedRequest & { slug: string },
  PaginatedResponseWithSlug<Discover>,
  { slug: string }
>();

export const getUserConversations = createAsyncAction(
  'users/GET_USER_CONVERSATIONS_REQUEST',
  'users/GET_USER_CONVERSATIONS_SUCCESS',
  'users/GET_USER_CONVERSATIONS_FAILURE',
)<
  PaginatedRequest & { slug: string; search: string },
  PaginatedResponseWithSlug<Conversation>,
  { slug: string }
>();

export const getUserChallengeResponses = createAsyncAction(
  'users/GET_USER_CHALLENGE_RESPONSES_REQUEST',
  'users/GET_USER_CHALLENGE_RESPONSES_SUCCESS',
  'users/GET_USER_CHALLENGE_RESPONSES_FAILURE',
)<
  PaginatedRequest & { slug: string; search: string },
  PaginatedResponseWithSlug<ChallengeResponse>,
  { slug: string }
>();

export const getUserExerciseResponses = createAsyncAction(
  'users/GET_USER_EXERCISE_RESPONSES_REQUEST',
  'users/GET_USER_EXERCISE_RESPONSES_SUCCESS',
  'users/GET_USER_EXERCISE_RESPONSES_FAILURE',
)<
  PaginatedRequest & { slug: string; search: string },
  PaginatedResponseWithSlug<ExerciseResponse>,
  { slug: string }
>();

export const getUserFeedbackResponses = createAsyncAction(
  'users/GET_USER_FEEDBACK_REQUEST',
  'users/GET_USER_FEEDBACK_SUCCESS',
  'users/GET_USER_FEEDBACK_FAILURE',
)<
  PaginatedRequest & { slug: string; search: string },
  PaginatedResponseWithSlug<FeedbackRequest>,
  { slug: string }
>();

export const _upsertUsersById = createAction('feedback/_UPSERT_USERS_BY_ID')<
  UpsertByIdPayload<User>
>();

export const getHeatMap = createAsyncAction(
  'users/GET_HEATMAP_REQUEST',
  'users/GET_HEATMAP_SUCCESS',
  'users/GET_HEATMAP_FAILURE',
)<
  { id: string; slug: string },
  { data: MonthData[]; slug: string },
  undefined
>();

export const sendActivationEmail = createAsyncAction(
  'users/SEND_ACTIVATION_EMAIL_REQUEST',
  'users/SEND_ACTIVATION_EMAIL_SUCCESS',
  'users/SEND_ACTIVATION_EMAIL_FAILURE',
)<{ email: string }, undefined, undefined>();

export const getOutstandingUsers = createAsyncAction(
  'users/GET_OUTSTANDING_USERS_REQUEST',
  'users/GET_OUTSTANDING_USERS_SUCCESS',
  'users/GET_OUTSTANDING_USERS_FAILURE',
)<{ period: string }, PaginatedResponse<User>, undefined>();

export const followUser = createAsyncAction(
  'users/FOLLOW_USER_REQUEST',
  'users/FOLLOW_USER_SUCCESS',
  'users/FOLLOW_USER_FAILURE',
)<
  { id?: string; slug?: string },
  { id?: string; slug?: string },
  { id?: string; slug?: string }
>();

export const unfollowUser = createAsyncAction(
  'users/UNFOLLOW_USER_REQUEST',
  'users/UNFOLLOW_USER_SUCCESS',
  'users/UNFOLLOW_USER_FAILURE',
)<
  { id?: string; slug?: string },
  { id?: string; slug?: string },
  { id?: string; slug?: string }
>();

export const followUserList = createAsyncAction(
  'users/FOLLOW_USER_LIST_REQUEST',
  'users/FOLLOW_USER_LIST_SUCCESS',
  'users/FOLLOW_USER_LIST_FAILURE',
)<
  { id?: string; slug?: string; childId?: string; childSlug?: string },
  { id?: string; slug?: string; childId?: string },
  { id?: string; slug?: string }
>();

export const unfollowUserList = createAsyncAction(
  'users/UNFOLLOW_USER_LIST_REQUEST',
  'users/UNFOLLOW_USER_LIST_SUCCESS',
  'users/UNFOLLOW_USER_LIST_FAILURE',
)<
  { id?: string; slug?: string; childId?: string; childSlug?: string },
  { id?: string; slug?: string; childId?: string },
  { id?: string; slug?: string }
>();

export const getFollowers = createAsyncAction(
  'users/GET_FOLLOWERS_REQUEST',
  'users/GET_FOLLOWERS_SUCCESS',
  'users/GET_FOLLOWERS_FAILURE',
)<
  PaginatedRequest & { id: string; slug: string },
  PaginatedResponseWithSlug<User>,
  { slug: string }
>();

export const getFollowings = createAsyncAction(
  'users/GET_FOLLOWINGS_REQUEST',
  'users/GET_FOLLOWINGS_SUCCESS',
  'users/GET_FOLLOWINGS_FAILURE',
)<
  PaginatedRequest & { id: string; slug: string },
  PaginatedResponseWithSlug<User>,
  { slug: string }
>();
