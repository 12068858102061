import { Loader } from 'components';
import { User } from 'model';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import connect from './connect';
import { handleFollowUser } from './logic';
import { EmptyState } from './styles';
import { Props } from './types';
import UserCard from './UserCard';

const UserList: FC<Props> = ({
  parentUser,
  users,
  meUserId,
  isLoggedIn,
  className,
  followUser,
  unfollowUser,
  closeModal,
}) => {
  const { push } = useHistory();

  const onClickFollowUser = useCallback(
    (user: User) => {
      handleFollowUser({
        slug: parentUser.slug,
        userId: parentUser.id,
        followed: !!user.followed,
        childId: user.id,
        childSlug: user.slug,
        followUser,
        unfollowUser,
      });
    },
    [followUser, parentUser.id, parentUser.slug, unfollowUser],
  );

  const onClickProfileUser = (userSlug: User['slug']) => {
    closeModal();
    push(`/members/${userSlug}`);
  };

  return (
    <div className={className}>
      {!!users?.loading ? (
        <EmptyState>
          <Loader />
        </EmptyState>
      ) : users?.results?.length ? (
        users?.results.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            meUserId={meUserId}
            isLoggedIn={isLoggedIn}
            onClickFollowUser={onClickFollowUser}
            onClickProfileUser={onClickProfileUser}
          />
        ))
      ) : (
        <EmptyState>No users found</EmptyState>
      )}
    </div>
  );
};

export default connect(UserList);
