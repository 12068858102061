import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';
import {
  followUser,
  getUsers,
  selectUsers,
  unfollowUser,
} from 'redux/modules/users';

const mapStateToProps = (state: RootState) => ({
  loading: state.users.loading,
  count: state.users.count,
  members: selectUsers(state),
  isLoggedIn: !!state.auth.user,
});

export const mapDispatchToProps = {
  getMembers: getUsers.request,
  followUser: followUser.request,
  unfollowUser: unfollowUser.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
